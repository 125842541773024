import React, {CSSProperties, useEffect} from 'react';
import {ImageType} from '../types/ImageType';

export interface ImageProps {
  id?: string;
  loading?: 'eager' | 'lazy';
  image?: ImageType;
  className?: string;
  style?: CSSProperties;
  width?: string | number;
  height?: string | number;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
  showOriginalImage?: boolean;
}

function Component({image, showOriginalImage = false, ...props}: ImageProps) {
  // useEffect(() => {
  //   if (!image?.image.altText) {
  //     console.warn(`${image?.image.url} has no alt.`);
  //   }
  // }, [image?.image.altText, image?.image.url]);
  //
  // useEffect(() => {
  //   if (!props.width) {
  //     console.warn(`${image?.image.url} has no width.`);
  //   }
  // }, [props.width, image?.image.url]);
  //
  // useEffect(() => {
  //   if (!props.height) {
  //     console.warn(`${image?.image.url} has no height.`);
  //   }
  // }, [props.height, image?.image.url]);

  return (
    <img
      onContextMenu={(e) => e.preventDefault()}
      src={showOriginalImage ? image?.image.originalUrl : image?.image.url}
      alt={image?.image.altText}
      {...props}
    />
  );
}

export const Image = React.memo(Component);
